import React from 'react';

import {
  ClassName,
  I18nText,
  IsLoading,
  ErrorMessage,
  IsDisabled
} from '../../../../../types';

import { SelectProjectAndTaskModalButtonOnSubmitAction } from './SelectProjectAndTaskModalButton.types';

import { useSelectProjectAndTaskForm } from '../../forms/SelectProjectAndTaskForm/hooks/useSelectProjectAndTaskForm';

import { SelectProjectAndTaskForm } from '../../forms/SelectProjectAndTaskForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { IconsEnum } from '../../../../../assets/icons/types';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface SelectProjectAndTaskModalButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  i18nSubmitText?: I18nText;
  i18nTitle?: I18nText;
  isLoading?: IsLoading;
  submitDisabled?: IsDisabled;
  errorMessage?: ErrorMessage;
  onClose?: () => void;
  onSubmit: SelectProjectAndTaskModalButtonOnSubmitAction;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  tooltipSingleton?: boolean;
  tooltipPlacement?: TooltipPlacement;
  tooltipI18nText?: I18nText;
  disabled?: boolean;
}

const SELECT_PROJECT_AND_TASK_FORM = 'select-project-and-task-form';

function SelectProjectAndTaskModalButton({
  className,
  i18nText,
  i18nSubmitText,
  i18nTitle,
  isLoading,
  submitDisabled,
  errorMessage,
  onClose,
  onSubmit,
  iconClassName,
  icon,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  disabled
}: SelectProjectAndTaskModalButtonProps) {
  const {
    control,
    resetForm,
    handleSelectProjectAndTask,
    validationErrors,
    watchSelectedProjectUuid,
    selectedTaskEmptyValue
  } = useSelectProjectAndTaskForm({
    onSubmit
  });

  return (
    <FormModalButton
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      className={className}
      form={SELECT_PROJECT_AND_TASK_FORM}
      i18nSubmitText={i18nSubmitText}
      i18nTitle={i18nTitle}
      isLoading={isLoading}
      submitDisabled={submitDisabled}
      onOpen={resetForm}
      onClose={onClose}
      onSubmit={handleSelectProjectAndTask}
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={disabled}
    >
      <SelectProjectAndTaskForm
        form={SELECT_PROJECT_AND_TASK_FORM}
        control={control}
        isLoading={isLoading}
        selectedProjectValidationError={
          validationErrors.selectedProjectValidationError
        }
        watchSelectedProjectUuid={watchSelectedProjectUuid}
        selectedTaskEmptyValue={selectedTaskEmptyValue}
      />

      <div className="px-4">
        <AlertMessage message={errorMessage} />
      </div>
    </FormModalButton>
  );
}

export default SelectProjectAndTaskModalButton;
