import React, { memo } from 'react';

import { FiltersProps, useFilters } from '../hooks/useFilters';

import { CheckboxGroup, CheckboxItemsType } from '../../CheckboxGroup';

type CheckboxGroupFilterValue = string[] | undefined;

interface CheckboxGroupFilterProps
  extends FiltersProps<CheckboxGroupFilterValue> {
  items: CheckboxItemsType;
  inputWrapperClassName?: string;
}

function CheckboxGroupFilter({
  name,
  value,
  items,
  inputWrapperClassName,
  onChange
}: CheckboxGroupFilterProps) {
  const { updatedValue, handleChange } = useFilters<CheckboxGroupFilterValue>({
    name,
    value,
    emptyValue: [],
    onChange
  });

  return (
    <CheckboxGroup
      name={name}
      value={updatedValue || []}
      items={items}
      inputWrapperClassName={inputWrapperClassName}
      onChange={handleChange}
    />
  );
}

export default memo(CheckboxGroupFilter);
