import React, { memo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  CheckboxAlphabeticalGroup,
  CheckboxAlphabeticalGroupItemsType,
  CheckboxAlphabeticalGroupI18nSearchPlaceholder,
  CheckboxAlphabeticalGroupSearchValue,
  CheckboxAlphabeticalGroupSelectedItemsFetched,
  CheckboxAlphabeticalGroupFetchMoreButton,
  CheckboxAlphabeticalGroupOnSearch,
  CheckboxAlphabeticalGroupOnChange
} from '../../CheckboxAlphabeticalGroup';

type CheckboxAlphabeticalGroupFilterValue = string[] | undefined;

interface CheckboxAlphabeticalGroupFilterProps {
  name: string;
  value: CheckboxAlphabeticalGroupFilterValue;
  onChange: (
    changedFilters: { [name: string]: CheckboxAlphabeticalGroupFilterValue },
    removeFilters?: string[]
  ) => void;
  sortedItems?: CheckboxAlphabeticalGroupItemsType;
  selectedItems?: CheckboxAlphabeticalGroupItemsType;
  itemsFetched?: CheckboxAlphabeticalGroupSelectedItemsFetched;
  i18nSearchPlaceholder?: CheckboxAlphabeticalGroupI18nSearchPlaceholder;
  searchValue?: CheckboxAlphabeticalGroupSearchValue;
  fetchMoreButton?: CheckboxAlphabeticalGroupFetchMoreButton;
  onSearch?: CheckboxAlphabeticalGroupOnSearch;
}

function CheckboxAlphabeticalGroupFilter({
  name,
  value,
  sortedItems,
  selectedItems,
  itemsFetched,
  i18nSearchPlaceholder,
  searchValue,
  fetchMoreButton,
  onSearch,
  onChange
}: CheckboxAlphabeticalGroupFilterProps) {
  const handleChange = useCallback<CheckboxAlphabeticalGroupOnChange>(
    (newValue) => {
      onChange({ [name]: newValue }, isEmpty(newValue) ? [name] : []);
    },
    [name, onChange]
  );

  return (
    <CheckboxAlphabeticalGroup
      name={name}
      value={value}
      sortedItems={sortedItems}
      selectedItems={selectedItems}
      itemsFetched={itemsFetched}
      i18nSearchPlaceholder={i18nSearchPlaceholder}
      searchValue={searchValue}
      fetchMoreButton={fetchMoreButton}
      onSearch={onSearch}
      onChange={handleChange}
    />
  );
}

export default memo(CheckboxAlphabeticalGroupFilter);
