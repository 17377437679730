import React from 'react';

import { ErrorMessage, IsLoading } from '../../../../../types';

import {
  SelectProjectAndTaskFormData,
  SelectProjectAndTaskFormFields
} from './SelectProjectAndTaskForm.types';
import { ProjectUUID } from '../../../../projects/projectsTypes';

import {
  ProjectUuidsSelectField,
  ProjectUuidsSelectFieldControlProps
} from '../../../../projects/helpers/ProjectUuidsSelectField';
import { MultiSelectDataType } from '../../../../../helpers/MultiSelect/types';
import { Form } from '../../../../../helpers/Form';
import { Translate } from '../../../../../helpers/Translate';
import { TasksByProjectSelectField } from '../../../../tasks/helpers/TasksByProjectSelectField';

import { projectsKeys, tasksKeys } from '../../../../../locales/keys';

interface SelectProjectAndTaskFormProps {
  form: string;
  isLoading?: IsLoading;
  selectedProjectValidationError?: ErrorMessage;
  watchSelectedProjectUuid: ProjectUUID;
  selectedTaskEmptyValue: MultiSelectDataType;
}

function SelectProjectAndTaskForm({
  form,
  control,
  isLoading,
  selectedProjectValidationError,
  watchSelectedProjectUuid,
  selectedTaskEmptyValue
}: SelectProjectAndTaskFormProps &
  ProjectUuidsSelectFieldControlProps<SelectProjectAndTaskFormData>) {
  return (
    <Form className="flex-1 overflow-y-auto px-2 z-0" id={form}>
      <div className="p-4">
        <div>
          <ProjectUuidsSelectField
            // ignore because of "Type instantiation is excessively deep and possibly infinite." error
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            control={control}
            name={SelectProjectAndTaskFormFields.SELECTED_PROJECT}
            i18nPlaceholder={projectsKeys.selectSingular}
            inputWrapperClassName="mt-1 relative"
            i18nLabel={projectsKeys.selectSingular}
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            disabled={isLoading}
          />
          {selectedProjectValidationError ? (
            <p className="mt-2 text-sm text-red-600">
              <Translate id={selectedProjectValidationError} />
            </p>
          ) : null}
        </div>
      </div>
      <div className="p-4">
        <div>
          {watchSelectedProjectUuid ? (
            <TasksByProjectSelectField<SelectProjectAndTaskFormData>
              // ignore because of "Type instantiation is excessively deep and possibly infinite." error
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              projectUuid={watchSelectedProjectUuid}
              control={control}
              name={SelectProjectAndTaskFormFields.SELECTED_TASK}
              emptyValue={selectedTaskEmptyValue}
              i18nPlaceholder={tasksKeys.selectSingular}
              inputWrapperClassName="mt-1 relative"
              i18nLabel={tasksKeys.selectSingular}
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
              disabled={isLoading}
              multi={false}
            />
          ) : null}
        </div>
      </div>
    </Form>
  );
}

export default SelectProjectAndTaskForm;
