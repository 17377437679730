import isEmpty from 'lodash/isEmpty';

import { SearchResultsUsersList } from '../InviteUsersModalButton/components/InviteUsersModalBody/components/SearchResultsUsersList';
import { RecentlySelectedUsersList } from '../InviteUsersModalButton/components/InviteUsersModalBody/components/RecentlySelectedUsersList';
import { SelectedUsersList } from '../InviteUsersModalButton/components/InviteUsersModalBody/components/SelectedUsersList';

import { useSelectClientModalButton } from './hooks/useSelectClientModalButton';

import { TextFilter } from '../../../../helpers/filters/TextFilter';
import { SimpleModalButton } from '../../../../helpers/buttons/SimpleModalButton';
import { CheckPermissions } from '../../../../helpers/CheckPermissions';

import { I18nText } from '../../../../types';
import { UserID, UserUUID } from '../../../users/usersTypes';

import { IconsEnum } from '../../../../assets/icons/types';

import { categoriesKeys, stringsKeys, words } from '../../../../locales/keys';

interface SelectClientModalButtonProps {
  onAddClient: (ids: UserID[]) => void;
  i18nText?: I18nText;
  icon?: IconsEnum;
  className?: string;
  iconClassName?: string;
  initialUserUuids?: UserUUID[];
}

const SELECT_CLIENT_TAB = 'clients';

function SelectClientModalButton({
  onAddClient,
  i18nText,
  icon,
  className,
  iconClassName,
  initialUserUuids
}: SelectClientModalButtonProps) {
  const {
    handleCheckUser,
    handleCheckAllUsers,
    handleChangeFullName,
    handleAfterSubmit,
    handleOpen,
    handleDeselectUser,
    fullName,
    selectedUserIds,
    selectedUsers
  } = useSelectClientModalButton({ onAddClient });

  return (
    <SimpleModalButton
      className={
        className ||
        'py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full'
      }
      i18nSubmitText={words.submit}
      i18nText={i18nText}
      i18nTitle={categoriesKeys.client.select}
      icon={icon}
      iconClassName={iconClassName || 'h-9 w-9 p-2 mr-2'}
      onOpen={handleOpen}
      afterSubmit={handleAfterSubmit}
      submitDisabled={isEmpty(selectedUserIds)}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
    >
      <div className="px-4">
        <div className="space-y-6 mt-2">
          <div className="flex items-center w-full">
            <TextFilter
              id="search-user-by-name"
              className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
              inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
              i18nPlaceholder="words.search_user_by_name"
              name="fullName"
              onChange={handleChangeFullName}
              value={fullName}
            />
          </div>
          <div className="mt-6">
            {fullName ? (
              <SearchResultsUsersList
                fullName={fullName}
                onCheck={handleCheckUser}
                onCheckAll={handleCheckAllUsers}
                selectedUserIds={selectedUserIds}
                tab={SELECT_CLIENT_TAB}
                invitedUserUuids={initialUserUuids}
              />
            ) : (
              <CheckPermissions action="read_recently_selected_clients">
                <RecentlySelectedUsersList
                  i18nNoResultsMessage={
                    stringsKeys.startTypingToSearchForClients
                  }
                  onCheck={handleCheckUser}
                  onCheckAll={handleCheckAllUsers}
                  selectedUserIds={selectedUserIds}
                  tab={SELECT_CLIENT_TAB}
                  invitedUserUuids={initialUserUuids}
                />
              </CheckPermissions>
            )}

            <SelectedUsersList
              onDeselectUser={handleDeselectUser}
              selectedUsers={selectedUsers}
            />
          </div>
        </div>
      </div>
    </SimpleModalButton>
  );
}

export default SelectClientModalButton;
